import React from "react";
import B1 from "../components/svg/b1.inline.svg";
import Apple from "../components/svg/apple.inline.svg";
import Beats from "../components/svg/beats.inline.svg";
import Tokr from "../components/svg/tokr.inline.svg";
import Plx from 'react-plx';


const HomeLogosData = [
  {
    start: 0,
    end: 1,
    properties: [
      {
        startValue: 999,
        endValue: 1000,
        property: "translateY"
      },
    ]
  },
  {
    start: 3600,
    end: 4600,
    properties: [
      {
        startValue: 1000,
        endValue: 0,
        property: "translateY"
      },
    ]
  },
];
class HomeLogos extends React.Component {
  render() {
    return (
      <Plx parallaxData={ HomeLogosData } animateWhenNotInViewport={ true }>
        <div id="homeLogos">
          <PlxB1 />
          <PlxApple />
          <PlxBeats />
          <PlxTokr />
        </div>
      </Plx>
    );
  }
}
const PlxB1Data = [
  {
    start: '#workApple',
    duration: 100,
    properties: [
      {
        startValue: 1,
        endValue: .2,
        property: "opacity"
      },
    ]
  },
];
class PlxB1 extends React.Component {
  render() {
    return (
      <Plx className="clientLogo" parallaxData={ PlxB1Data } animateWhenNotInViewport={ true }>
        <a href="#workB1" className="b1"><B1 /></a>
      </Plx>
    );
  }
}
const PlxAppleData = [
  {
    start: '#workB1',
    duration: 100,
    properties: [
      {
        startValue: 1,
        endValue: .2,
        property: "opacity"
      },
    ]
  },
  {
    start: '#workApple',
    startOffset: "75vh",
    duration: 100,
    properties: [
      {
        startValue: .2,
        endValue: 1,
        property: "opacity"
      },
    ]
  },
  {
    start: '#workBeats',
    startOffset: "75vh",
    duration: 100,
    properties: [
      {
        startValue: 1,
        endValue: .2,
        property: "opacity"
      },
    ]
  },
];
class PlxApple extends React.Component {
  render() {
    return (
      <Plx className="clientLogo" parallaxData={ PlxAppleData } animateWhenNotInViewport={ true }>
        <a href="#workApple" className="apple"><Apple /></a>
      </Plx>
    );
  }
}
const PlxBeatsData = [
  {
    start: '#workB1',
    duration: 100,
    properties: [
      {
        startValue: 1,
        endValue: .2,
        property: "opacity"
      },
    ]
  },
  {
    start: '#workBeats',
    startOffset: "75vh",
    duration: 100,
    properties: [
      {
        startValue: .2,
        endValue: 1,
        property: "opacity"
      },
    ]
  },
  {
    start: '#workTokr',
    startOffset: "75vh",
    duration: 100,
    properties: [
      {
        startValue: 1,
        endValue: .2,
        property: "opacity"
      },
    ]
  },
];
class PlxBeats extends React.Component {
  render() {
    return (
      <Plx className="clientLogo" parallaxData={ PlxBeatsData } animateWhenNotInViewport={ true }>
        <a href="#workBeats" className="Beats"><Beats /></a>
      </Plx>
    );
  }
}

const PlxTokrData = [
  {
    start: '#workB1',
    duration: 100,
    properties: [
      {
        startValue: 1,
        endValue: .2,
        property: "opacity"
      },
    ]
  },
  {
    start: '#workTokr',
    startOffset: "75vh",
    duration: 100,
    properties: [
      {
        startValue: .2,
        endValue: 1,
        property: "opacity"
      },
    ]
  },
];
class PlxTokr extends React.Component {
  render() {
    return (
      <Plx className="clientLogo" parallaxData={ PlxTokrData } animateWhenNotInViewport={ true }>
        <a href="#workTokr" className="Tokr"><Tokr /></a>
      </Plx>
    );
  }
}
export default () => (
  <div>
    <HomeLogos />
  </div>
)
