import React from "react"
import Email from "../components/svg/email.inline.svg";
import Phone from "../components/svg/phone.inline.svg";
import Location from "../components/svg/location.inline.svg";

export default () => (
  <div id="footer" className="section red">
    <div className="content">
      <div className="pad">
        <h1>Let's Work</h1>
        <h1 className="lineTwo"><span>Together.</span></h1><br/>
        <a href="mailto:brian@madequal.com" id="email"><span className="icon"><Email /></span>brian@madequal.com</a><br />
        <a href="tel:615-415-0696" id="phone"><span className="icon"><Phone /></span>(615) 415-0696</a><br />
        <span id="location"><span className="icon"><Location /></span>Nashville, TN</span>
      </div>
      <div class="rectangle one" />
      <div class="rectangle two" />
    </div>
  </div>
)
